.connexion-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/test.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.connexion-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    filter: blur(5px);
    z-index: -1;
}

.connexion-form {
    position: relative;
    z-index: 2;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="email"], input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #CA2E75;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: #581845;
}

.link-container {
    text-align: center;
    margin-top: 20px;
}

.inscription-link {
    color: #CA2E75;
    text-decoration: none;
}

.inscription-link:hover {
    text-decoration: underline;
}
.connexion-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #CA2E75;
}
