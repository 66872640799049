.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.product-form {
    margin-bottom: 40px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-input,
.form-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form-input:focus,
.form-button:focus {
    outline: none;
    border-color: #CA2E75;
}

.form-button {
    background-color: #CA2E75;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-button:hover {
    background-color: #CA2E75;
}

.products-table {
    width: 100%;
    border-collapse: collapse;
}

.products-table th,
.products-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.products-table th {
    background-color: #581845;
    color: white;
}

.products-table td img {
    max-width: 50px;
    height: auto;
    border-radius: 5px;
}

.products-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.products-table tr:hover {
    background-color: #ddd;
}

button {
    cursor: pointer;
    padding: 5px 10px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    background-color: #CA2E75;
    color: white;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #CA2E75;
}

button:disabled {
    background-color: #ccc;
}
