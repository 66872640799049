/* AppsUsage.css */

.container {
    padding: 20px 20px 0px 20px;
    background-color: #f7f7f7;
    display: flex;
    gap: 30px;
    /* flex-direction: column; */
    align-items: center;
}
.container2 {
    padding: 20px 20px;
    background-color: #f7f7f7;
    display: flex;
    gap: 50px;
    flex-direction: column;
    align-items: center;
}

.chart-container {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 60%;
    /* max-width: 800px; */
}
.line-container {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width:80%;
    /* max-width: 800px; */
}
.pie-container {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 40%;
    /* padding: 12px; */
    /* max-width: 800px; */
}

.chart-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
}

.canvas-container {
    position: relative;
    width: 100%;
    height: 400px; /* Ajuster en fonction de vos besoins */
}

.loading-text, .error-text {
    font-size: 18px;
    margin-top: 20px;
    text-align: center;
}

.loading-spinner {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333; /* Couleur de la bordure gauche */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

/* Animation de chargement */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


