    /* footer.css */
.footer {
    background-color: #6A0D47;
    color: #FFFFFF;
    padding: 15px 20px;
    text-align: center;
    border-top: 2px solid #F885CC;
    font-family: 'Open Sans', sans-serif;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.footer p {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer:hover {
    background-color: #7a1054;
    border-top-color: #f9a6d8;
}

body {
    margin: 0;
    padding: 0;
    margin-bottom: 60px;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    position: relative;
    padding-bottom: 60px;
}

.main-content {
    min-height: calc(100vh - 60px);
    padding-bottom: 60px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .footer {
        padding: 10px;
        height: 40px;
    }

    .footer p {
        font-size: 0.8rem;
    }

    body {
        margin-bottom: 50px;
    }

    .main-content {
        min-height: calc(100vh - 40px);
        padding-bottom: 40px;
    }
}

@media (max-width: 480px) {
    .footer {
        height: 35px;
        padding: 8px;
    }

    .footer p {
        font-size: 0.75rem;
    }

    body {
        margin-bottom: 45px;
    }
}

/* Animation pour le texte au hover */
.footer p {
    transition: transform 0.3s ease;
}

.footer:hover p {
    transform: scale(1.02);
}