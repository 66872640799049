.body {
    background-color: #f0f2f5;
    color: #333;
}

.drawerPaper {
    color: #ffffff;
    background-color: #581845;
}

.appBar {
    background-color: #CA2E75 !important; /* Override Material-UI color */
}

.content {
    padding: 20px;
}

div .Title {
    background-color: rgb(229 231 235);
    /* margin-top: 6%; */
    padding: 10px 25px 10px 25px;
    border-bottom: 2px solid rgb(209 213 219);
    position: sticky;
}

.user-table th {
    background-color: #581845;
    color: white;
}

.user-table {
    width: 100%;
    border-collapse: collapse;
}


