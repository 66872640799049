/* Statistique.css */

/* Styles spécifiques au conteneur des statistiques */
.statistique-container {
    padding: 20px;
    display: flex;
    gap: 40px;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    /* width: 80%;
    margin: auto; */
}

/* Titre des statistiques */
.statistique-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}