/* src/components/auth/Inscription.css */
.inscription-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url('../assets/test.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.inscription-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.inscription-form {
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 1rem;
    width: 100%;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    box-sizing: border-box;
}

button[type="submit"] {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #CA2E75;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
}

button[type="submit"]:hover {
    background-color: #581845;
}

.link-container {
    text-align: center;
    margin-top: 1rem;
}

.inscription-link {
    color: #CA2E75;
    text-decoration: none;
    font-weight: bold;
}

.inscription-link:hover {
    text-decoration: underline;
}

.link-container {
    text-align: center;
    margin-top: 20px;
}

.inscription-link {
    color: #CA2E75;
    text-decoration: none;
}

.inscription-link:hover {
    text-decoration: underline;
}
