
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #6A0D47;
    color: #F885CC;
}

.header-brand h1 {
    margin: 0;
    font-size: 24px;
    cursor: pointer;
}

.header-nav .nav-link {
    margin-right: 20px;
    text-decoration: none;
    color: #F885CC;
    font-weight: bold;
    transition: color 0.3s ease;
}

.header-nav .nav-link:last-child {
    margin-right: 0;
}

.header-nav .nav-link:hover {
    color: #ddd;
}
.logo {
    height: 50px;
    width: auto;
    margin-right: 20px;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
    }

    .header-nav .nav-link {
        margin-top: 10px;
    }
}
